.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: 10px;
    background-color: #fff;
    color: #444;
    max-width:100%
  }
  
 .grid-item{
  
 }

  .grid-column-1 {grid-column: 1;}
  .grid-column-2 {grid-column: 2;}
  .grid-column-3 {grid-column: 3;}
  .grid-column-4 {grid-column: 4;}
  .grid-column-5 {grid-column: 5;}
  .grid-column-6 {grid-column: 6;}
  .grid-column-7 {grid-column: 7;}
  .grid-column-8 {grid-column: 8;}

  .grid-row-1 {grid-row: 1;}
  .grid-row-2 {grid-row: 2;}
  .grid-row-3 {grid-row: 3;}
  .grid-row-4 {grid-row: 4;}
  .grid-row-5 {grid-row: 5;}
  .grid-row-6 {grid-row: 6;}
  .grid-row-7 {grid-row: 7;}
  .grid-row-8 {grid-row: 8;}